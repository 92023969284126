import {
  StyleParamType,
  createStylesParams,
  wixColorParam,
  ISiteTextPreset,
  IWixStyleFont,
  wixFontParam,
} from '@wix/tpa-settings'
import {IMAGE_SCALING} from '@wix/wix-events-commons-statics'
import {Override} from '../../commons/types/utils'
import {PageComponentTextSettings} from './settingsParams'

type IBooleans = {
  shortDateLocationVisible: StyleParamType.Boolean
  descriptionVisible: StyleParamType.Boolean
  membersVisible: StyleParamType.Boolean
  membershipsVisible: StyleParamType.Boolean
  groupVisible: StyleParamType.Boolean
  aboutSectionVisible: StyleParamType.Boolean
  scheduleVisible: StyleParamType.Boolean
  mapVisible: StyleParamType.Boolean
  socialShareVisible: StyleParamType.Boolean
  readMoreButtonVisible: StyleParamType.Boolean
  rsvpButtonVisible: StyleParamType.Boolean
  headerRegistrationButtonVisible: StyleParamType.Boolean
  pageRegistrationButtonVisible: StyleParamType.Boolean
  summaryBoxVisible: StyleParamType.Boolean
  responsive: StyleParamType.Boolean
}

type INumbers = {
  headerLayout: StyleParamType.Number
  headerImageAlignment: StyleParamType.Number
  headerBorderWidth: StyleParamType.Number
  evTicketDividerSize: StyleParamType.Number
  rsvpButtonBorderWidth: StyleParamType.Number
  rsvpButtonBorderRadius: StyleParamType.Number
  rsvpButtonRoundedBorderRadius: StyleParamType.Number
  formButtonBorderWidth: StyleParamType.Number
  formButtonBorderRadius: StyleParamType.Number
  formButtonRoundedBorderRadius: StyleParamType.Number
  formSummaryBoxBorderSize: StyleParamType.Number
  imageOpacity: StyleParamType.Number
  buttonStyle: StyleParamType.Number
  imageRatio: StyleParamType.Number
  imageScaling: StyleParamType.Number
  imagePosition: StyleParamType.Number
  headerAlignment: StyleParamType.Number
  contentAlignment: StyleParamType.Number
  formButtonStyle: StyleParamType.Number
  mobileShortDateTimeSize: StyleParamType.Number
  mobileEventTitleSize: StyleParamType.Number
  mobileDescriptionSize: StyleParamType.Number
  mobileButtonTextSize: StyleParamType.Number
  mobileSubtitlesSize: StyleParamType.Number
  mobileTextSize: StyleParamType.Number
  mobileScheduleTimeSize: StyleParamType.Number
  mobileScheduleDurationSize: StyleParamType.Number
  mobileScheduleTitleSize: StyleParamType.Number
  mobileScheduleLocationSize: StyleParamType.Number
  mobileTicketsPickerTicketNameSize: StyleParamType.Number
}

type IStyles = {
  shortDateLocationFont: StyleParamType.Font
  shortDateLocationColor: StyleParamType.Color
  descriptionFont: StyleParamType.Font
  descriptionColor: StyleParamType.Color
  titleFont: StyleParamType.Font
  titleColor: StyleParamType.Color
  subtitlesFont: StyleParamType.Font
  subtitlesColor: StyleParamType.Color
  textFont: StyleParamType.Font
  textColor: StyleParamType.Color
  evTicketNameFont: StyleParamType.Font
  evTicketNameColor: StyleParamType.Color
  evTicketAdditionalInfoColor: StyleParamType.Color
  scheduleTimeFont: StyleParamType.Font
  scheduleTimeColor: StyleParamType.Color
  scheduleDurationFont: StyleParamType.Font
  scheduleDurationColor: StyleParamType.Color
  scheduleTitleFont: StyleParamType.Font
  scheduleTitleColor: StyleParamType.Color
  scheduleLocationFont: StyleParamType.Font
  scheduleLocationColor: StyleParamType.Color
  headerBackgroundColor: StyleParamType.Color
  evBackgroundColor: StyleParamType.Color
  headerBorderOpacityAndColor: StyleParamType.Color
  colorBehindImage: StyleParamType.Color
  rsvpHollowButtonColor: StyleParamType.Color
  rsvpButtonBorderColor: StyleParamType.Color
  secondRsvpHollowButtonColor: StyleParamType.Color
  secondRsvpButtonBorderColor: StyleParamType.Color
  rsvpHollowButtonFont: StyleParamType.Font
  rsvpButtonFont: StyleParamType.Font
  rsvpButtonColor: StyleParamType.Color
  rsvpButtonBackgroundColor: StyleParamType.Color
  secondRsvpButtonColor: StyleParamType.Color
  secondRsvpButtonBackgroundColor: StyleParamType.Color
  formSummaryTitleFont: StyleParamType.Font
  formSummaryBodyFont: StyleParamType.Font
  formSummaryColor: StyleParamType.Color
  formSummaryBoxBackgroundColor: StyleParamType.Color
  formSummaryBoxBorderColor: StyleParamType.Color
  formTitleFont: StyleParamType.Font
  formTitleColor: StyleParamType.Color
  formLabelsFont: StyleParamType.Font
  formLabelsColor: StyleParamType.Color
  formBackgroundColor: StyleParamType.Color
  formHollowButtonFont: StyleParamType.Font
  formHollowButtonColor: StyleParamType.Color
  formButtonBorderColor: StyleParamType.Color
  formButtonBackgroundColor: StyleParamType.Color
  formFullButtonColor: StyleParamType.Color
  formFullButtonFont: StyleParamType.Font
}

type IStylesParams = INumbers & IBooleans & IStyles

export type PageComponentSettings = Override<INumbers, number> &
  Override<IBooleans, boolean> & {texts: PageComponentTextSettings}

type ParamsMapper<Type, Val> = {
  [Property in keyof Type]: Val
}

export type DetailsStyleParams = {
  numbers: ParamsMapper<INumbers, number>
  booleans: ParamsMapper<IBooleans, boolean> & {responsive: boolean; __IS_RTL__: boolean}
  colors: IStyles
  fonts: IStyles
}

const getDefaultFont = (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) => {
  return ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }
}

export default createStylesParams<IStylesParams>({
  headerLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  headerImageAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  headerAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  contentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  buttonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageScaling: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_SCALING.CROP,
  },
  imagePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  headerBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  evTicketDividerSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  rsvpButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formSummaryBoxBorderSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  shortDateLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  shortDateLocationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 18,
    }),
  },
  shortDateLocationVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 60,
    }),
  },
  descriptionVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  subtitlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subtitlesFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 30,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  evTicketNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  evTicketNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 20,
    }),
  },
  evTicketAdditionalInfoColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleTimeColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleTimeFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  scheduleDurationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  scheduleDurationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  scheduleTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  scheduleLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleLocationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  evBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerBorderOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  imageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  colorBehindImage: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-7'),
  },
  rsvpHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  rsvpHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  rsvpButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  rsvpButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  rsvpButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  rsvpButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  secondRsvpButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  membersVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  aboutSectionVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  groupVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  membershipsVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  socialShareVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  mapVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  scheduleVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  rsvpButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  headerRegistrationButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  pageRegistrationButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  readMoreButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  formSummaryColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formSummaryTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 20,
    }),
  },
  formSummaryBodyFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-S', {
      size: 14,
    }),
  },
  formSummaryBoxBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formSummaryBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  formTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 30,
    }),
  },
  formLabelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formLabelsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  formBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  formHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formFullButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  formFullButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  formButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  summaryBoxVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  responsive: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  mobileShortDateTimeSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileEventTitleSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  mobileDescriptionSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileButtonTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobileSubtitlesSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  mobileTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleTimeSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleDurationSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleTitleSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleLocationSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileTicketsPickerTicketNameSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
})

export {customCssVars} from './custom-css-vars'
