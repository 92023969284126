import {IStyleParam} from '@wix/tpa-settings'
import stylesParams, {DetailsStyleParams} from './stylesParams'

interface CustomCssVarsParams {
  styleParams: DetailsStyleParams
}

export const customCssVars = ({styleParams}: CustomCssVarsParams) => {
  const styles = {
    get: (param: IStyleParam) => styleParams.booleans[param.key] ?? styleParams.numbers[param.key],
  }

  const isShortDateLocationVisible = () => styles.get(stylesParams.shortDateLocationVisible)

  const isDescriptionVisible = () => styles.get(stylesParams.descriptionVisible)

  return {
    shortDateLocationVisibility: isShortDateLocationVisible() ? 'block' : 'none',
    descriptionVisiblity: isDescriptionVisible() ? 'block' : 'none',
  }
}
