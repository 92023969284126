import React from 'react'
import {Button, ButtonPriority} from 'wix-ui-tpa/cssVars'
import classNames from 'classnames'
import {classes} from './secondary-button.st.css'
import {SecondaryButtonProps} from './index'

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  hollowButton,
  onClick,
  className,
  mobile,
  roundedButton,
  dataHook,
  narrowerButton,
  children,
}) => {
  let designClass
  if (hollowButton) {
    designClass = roundedButton ? classes.hollowRound : classes.hollowSquare
  } else {
    designClass = roundedButton ? classes.fullRound : classes.fullSquare
  }

  return (
    <Button
      upgrade
      priority={ButtonPriority.basicSecondary}
      className={classNames(classes.root, className, designClass, {
        [classes.mobile]: mobile,
        [classes.narrowerButton]: narrowerButton,
      })}
      onClick={onClick}
      data-hook={dataHook}
    >
      {children}
    </Button>
  )
}
