import {withTranslation} from '@wix/yoshi-flow-editor'
import {connect} from '../../runtime-context/context'
import {DetailsPageAppProps} from '../../app/interfaces'
import {getUpdatesCount} from '../../../selectors/groups'
import {isEventDetailsMobileUpdateEnabled} from '../../../../../../commons/selectors/experiments'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {GroupActivity as Presentation} from './group-activity'
import {GroupActivityOwnProps, GroupActivityRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): GroupActivityRuntimeProps => ({
  updatesCount: getUpdatesCount(state),
  updatedMobileDesignEnabled: isEventDetailsMobileUpdateEnabled(state),
  isMobile: isMobile(state),
})

export const GroupActivity = connect<GroupActivityOwnProps, GroupActivityRuntimeProps>(mapRuntime)(
  withTranslation()(Presentation),
)
