import React from 'react'
import classNames from 'classnames'
import {ScheduleStatus} from '@wix/ambassador-wix-events-agenda/types'
import {SectionNotificationSize, SectionNotificationType} from 'wix-ui-tpa/cssVars'
import {EventDetailsAlignment} from '@wix/wix-events-commons-statics'
import {TableRow} from '../../../table-row'
import c from '../../../classnames.scss'
import {Error} from '../../../../../../../icons/Error'
import {SectionNotification} from '../../../styled-components/section-notification'
import s from './list.scss'
import {Time} from './time'
import {Description} from './description'
import {UpdatedIndication} from './updated-indication'
import {ListProps} from './index'

export const List = ({items, mobile, t, updatedMobileInterface, customAlignment}: ListProps) => (
  <ul
    className={classNames(s.list, {
      [s.staticAlignment]: !customAlignment,
      [s.alignLeft]: customAlignment === EventDetailsAlignment.LEFT,
      [s.alignCenter]: customAlignment === EventDetailsAlignment.CENTER,
      [s.alignRight]: customAlignment === EventDetailsAlignment.RIGHT,
    })}
  >
    {items
      .slice(0, 2)
      .map(({formattedTime, formattedDuration, name, stageName, tags, status, formattedUpdatedDate, id}, index) => {
        const canceled = status === ScheduleStatus.CANCELED
        return (
          <li key={id}>
            {canceled ? (
              <SectionNotification
                size={SectionNotificationSize.compact}
                type={SectionNotificationType.wired}
                className={s.cancelNotification}
                icon={<Error />}
                text={t('schedule_itemCanceled')}
              />
            ) : null}
            {!canceled && formattedUpdatedDate ? (
              <UpdatedIndication notFirstItem={index > 0} formattedUpdatedDate={formattedUpdatedDate} />
            ) : null}
            <TableRow
              mobile={mobile}
              config={{
                itemKey: `scheduleItem-${index}`,
                addDivider: !mobile || index !== 1,
                className: classNames(mobile ? s.mobileRow : s.desktopRow, {
                  [s.indicationRow]: canceled || formattedUpdatedDate,
                  [s.updatedMobileInterface]: updatedMobileInterface,
                }),
                dividerClassName: classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor),
                columns: [
                  {
                    className: s.timeColumn,
                    content: <Time time={formattedTime} duration={formattedDuration} />,
                  },
                  {
                    className: s.descriptionColumn,
                    contentClassName: s.descriptionContent,
                    content: <Description name={name} stageName={stageName} tags={tags} t={t} />,
                  },
                ],
              }}
            />
          </li>
        )
      })}
  </ul>
)
